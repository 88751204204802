import React from 'react';
import { Link } from 'gatsby';
import { string, arrayOf, shape, number, func } from 'prop-types';
import cn from 'classnames';

const Tabs = ({ options, activeTab, onClick }) => {
  return (
    <div className="navigation-tabs">
      <ul>
        {options.map(({ tab, link }, id) => (
          <li key={id} className={cn({ active: id == activeTab })}>
            <Link onClick={onClick} to={link}>
              {tab}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

Tabs.propTypes = {
  activeTab: number.isRequired,
  options: arrayOf(
    shape({
      tab: string.isRequired,
      link: string.isRequired,
    }),
  ).isRequired,
  onClick: func,
};

export default Tabs;
